import { VFC } from "react";

import { FormattedMessage } from "react-intl";

import atelierLogo from "../../assets/images/atelierhorizontal.png";
import { Button } from "../Button/Button";

export const NavbarCommon: VFC = () => {
  return (
    <div className="h-full flex justify-between items-center">
      <div className="flex items-center justify-start flex-1">
        <div className="flex justify-start flex-1">
          <a href="/home">
            <div className="flex flex-row items-center justify-center">
              <img
                src={atelierLogo}
                alt="atelier "
                className="flex flex-col items-center  mt-5 mb-9"
                width={150}
              />
              <span className="flex flex-col items-center mb-3 ml-2">BETA</span>
            </div>
          </a>
        </div>
        <nav className="flex space-x-10">
          <div className="relative">
            <a
              href="https://atelierapp.com/about"
              className="font-montserrat font-semibold text-divider hover:text-gray-900"
            >
              <FormattedMessage id="about" />
            </a>
          </div>
          <a
            href="https://medium.com/@atelierapp"
            className="font-montserrat font-semibold text-divider hover:text-gray-900"
          >
            <FormattedMessage id="blog" />
          </a>
          {/* <a
            href=""
            className="font-montserrat font-semibold text-divider hover:text-gray-900"
          >
            How to join Atelier
          </a> */}
          {/* <a
            href=""
            className="font-montserrat font-semibold text-divider hover:text-gray-900"
          >
            FAQ & Support
          </a> */}
        </nav>
        <div className="flex ml-11 ">
          {/* <a href="#">
            <Button className="" color="secondary">
              Sell on Atelier
            </Button>
          </a> */}
          <a
            href="https://apps.apple.com/app/atelier/id1565516356"
            className="ml-7"
          >
            <Button className="">
              <FormattedMessage id="downloadApp" />
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
