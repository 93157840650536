import { useCallback } from "react";

import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logoutApi } from "../api/logout";
import { updateAuthenticated } from "../redux/login/action-creators";

type Params = {
  skipNavigate?: true;
};

export const useLogout = ({ skipNavigate }: Params = {}) => {
  const logoutMutation = useMutation(logoutApi);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const logout = useCallback(() => {
    logoutMutation.mutate();
    dispatch(updateAuthenticated(false));
    localStorage.clear();
    queryClient.resetQueries("getMyProfile");
    if (!skipNavigate) {
      navigate("/login");
    }
  }, [dispatch, logoutMutation, navigate, queryClient, skipNavigate]);
  return { logout };
};
