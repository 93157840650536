import { VFC } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { getMyStore } from "../../api/get-my-store";
import { useLogout } from "../../hooks/use-logout";
import { ReactComponent as SearchIcon } from "../../svgs/search.svg";
import { Avatar } from "../Avatar/Avatar";
import { Button } from "../Button/Button";
import { Icon } from "../Icon";

export const NavbarDashboard: VFC = () => {
  const { formatMessage } = useIntl();
  const { data } = useQuery("getMyStore", getMyStore);
  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
  };
  return (
    <div className="h-full flex items-center">
      <div className="flex flex-1 items-center mr-20">
        {/* <div className="relative flex-1">
          <SearchIcon
            className="absolute fill-darkgray left-3 top-[9px]"
            height={20}
            width={20}
          />
          <input
            type="text"
            placeholder={formatMessage({
              id: "searchTransactionsInvoicesHelp",
            })}
            className="placeholder:text-black py-2 block w-full pl-10"
          />
        </div> */}
      </div>
      <a href={process.env.REACT_APP_API_URL}>
        <Button className="" color="secondary">
          <FormattedMessage id="goBackToAtelierapp" />
        </Button>
      </a>
      <div className="w-[70px]" />
      {/* <button className="bg-transparent mr-8">
        <Icon icon="message" className="fill-darkgray" size={25} />
      </button> */}
      {/* <button className="bg-transparent mr-8">
        <Indicator label="5">
          <Icon icon="notification" className="fill-darkgray" size={25} />
        </Indicator>
      </button> */}
      <div className="dropdown dropdown-end">
        <label tabIndex={0}>
          <button className="mr-8 bg-transparent text-darkgray">
            <div className="flex items-center">
              <span>{data?.name}</span>
              <Icon icon="chevronDown" className="fill-darkgray" />
            </div>
          </button>
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <Link to="/account-settings">
              <FormattedMessage id="accountSettings" />
            </Link>
          </li>
          <li>
            <Link to="/payment-method">
              <FormattedMessage id="paymentMethod" />
            </Link>
          </li>
          <li>
            <a href="#" onClick={handleLogout}>
              <FormattedMessage id="signOut" />
            </a>
          </li>
        </ul>
      </div>
      <Avatar imageUrl={data?.logo} size={55} />
    </div>
  );
};
