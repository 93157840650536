import { useEffect } from "react";

import { useQuery } from "react-query";
import * as Sentry from "@sentry/browser";

import { getMyProfile } from "../api/get-my-profile";

export const useSetSentryScope = () => {
  const { data, error } = useQuery("getMyProfile", getMyProfile, {
    retry: false,
  });
  useEffect(() => {
    if (data) {
      Sentry.setUser({ email: data.email });
    }
    if (error) {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [data, error]);
};
