export const LOCALES = {
  EN_US: "en-US",
  ES_PE: "es-PE",
};

export const DEFAULT_LOCALE_BY_LANGUAGE = {
  EN: LOCALES.EN_US,
  ES: LOCALES.ES_PE,
};

export const DEFAULT_LOCALE =
  DEFAULT_LOCALE_BY_LANGUAGE[
    process.env.REACT_APP_DEFAULT_LANGUAGE?.toUpperCase()
  ] ?? LOCALES.EN_US;
