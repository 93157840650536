import axios from "axios";

export const getMyStore = () => {
  return axios
    .get<{
      data: {
        id: number;
        name: string;
        internal_rating: number;
        customer_rating: number;
        story: string;
        logo: string;
        team: string;
        cover: string;
        qualities: { id: number }[];
        connected: boolean;
      };
    }>(`${process.env.REACT_APP_API_URL}/api/stores/my-store`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((data) => data.data.data);
};
