import { loginInitialState } from "./initial-state";
import { LoginState } from "./type";

export const loginReducer = (
  state: LoginState = loginInitialState,
  action
): LoginState => {
  switch (action.type) {
    case "LOGIN_SUBMIT":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        error: "Hubo un error",
      };

    case "UPDATE_AUTHENTICATED":
      return {
        ...state,
        authenticated: action.payload,
      };

    default:
      return state;
  }
};
