import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { IntlProvider } from "react-intl";
import axios from "axios";

import {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_BY_LANGUAGE,
  LOCALES,
} from "./constants";

type II18nContext = {
  locale: string;
  setLocale: (locale: string) => void;
  language: string;
};

const I18nContext = createContext<II18nContext>({
  setLocale: null,
  language: "en",
  locale: "",
});

export const useI18n = () => useContext(I18nContext);

export const I18nProvider: FC = ({ children }) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [messages, setMessages] = useState({});
  const supportedLocale = useMemo<string>(() => {
    const [language] = locale.split("-");
    const _supportedLocale =
      Object.values(LOCALES).find((l) => locale === l) ||
      DEFAULT_LOCALE_BY_LANGUAGE[language] ||
      DEFAULT_LOCALE;
    axios.defaults.headers.common["x-locale"] = _supportedLocale;
    return _supportedLocale;
  }, [locale]);
  useEffect(() => {
    import(`./locales/${supportedLocale}.json`).then(setMessages);
  }, [supportedLocale]);
  const value = useMemo(
    () => ({
      setLocale,
      language: supportedLocale.split("-")[0],
      locale: supportedLocale,
    }),
    [supportedLocale]
  );
  return (
    <IntlProvider
      locale={supportedLocale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages}
    >
      <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
    </IntlProvider>
  );
};
