import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";

import { Icon } from "../Icon";

type AvatarControl = {
  onUpload: (file: File, previewUrl: string) => void;
  error?: string;
};

type AvatarProps = {
  imageUrl?: string;
  size?: number;
  control?: AvatarControl;
};

export const Avatar: FC<AvatarProps> = ({ imageUrl, size = 126, control }) => {
  const [previewUrl, setPreviewUrl] = useState(imageUrl);
  const uploadInputRef = useRef<any>();
  const inner = useMemo(() => {
    if (previewUrl) {
      return (
        <img
          src={previewUrl}
          alt="avatar"
          className="object-contain object-center w-full h-full"
        />
      );
    }
    if (control) {
      return (
        <div className="h-full w-full flex justify-center items-center">
          <Icon icon="upload" className="w-8" />
        </div>
      );
    }
    return null;
  }, [control, previewUrl]);
  const uploadControl = useMemo(() => {
    if (control) {
      return (
        <input
          type="file"
          className="hidden"
          ref={uploadInputRef}
          accept="image/png, image/gif, image/jpeg"
          onChange={(ev) => {
            const file = ev.currentTarget.files[0];
            if (file) {
              const localUrl = URL.createObjectURL(file);
              setPreviewUrl(localUrl);
              control.onUpload(file, URL.createObjectURL(file));
            }
          }}
        />
      );
    }
    return null;
  }, [control]);
  const deleteControl = useMemo(() => {
    if (control) {
      return (
        <div className="ml-4">
          <Icon
            icon="trash"
            className="cursor-pointer"
            onClick={() => {
              control.onUpload(null, null);
              setPreviewUrl(null);
            }}
          />
        </div>
      );
    }
    return null;
  }, [control]);
  const error = useMemo(() => {
    if (control?.error) {
      return <span className="text-red-500">{control.error}</span>;
    }
    return null;
  }, [control?.error]);
  useEffect(() => {
    setPreviewUrl(imageUrl);
  }, [imageUrl]);
  return (
    <div className="flex items-center">
      <div className="flex-col items-center relative">
        <div
          className={classNames("border rounded-full overflow-hidden", {
            "cursor-pointer": !!control,
            "border-red-500": !!control?.error,
          })}
          style={{ width: size, height: size }}
          onClick={() => uploadInputRef.current?.click()}
        >
          {uploadControl}
          {inner}
        </div>
        <div className="absolute mt-2 w-full text-center">{error}</div>
      </div>
      {deleteControl}
    </div>
  );
};
