import { FormattedMessage } from "react-intl";

import { Icon } from "../Icon";

export const menu = [
  {
    label: <FormattedMessage id="home" />,
    to: "/home",
    icon: <Icon icon="home" className="fill-emerald" height={20} width={20} />,
  },
  {
    label: <FormattedMessage id="dashboard" />,
    to: "/dashboard",
    icon: <Icon icon="chart" className="fill-emerald" height={20} width={20} />,
  },
  {
    label: <FormattedMessage id="products" />,
    to: "/products",
    icon: (
      <Icon icon="bartCode" className="fill-emerald" height={20} width={20} />
    ),
  },
  {
    label: <FormattedMessage id="orders" />,
    to: "/orders",
    icon: (
      <Icon icon="invoice" className="fill-emerald" height={20} width={20} />
    ),
  },
  // {
  //   label: "Invoices",
  //   to: "/invoices",
  //   icon: (
  //     <Icon icon="invoice" className="fill-emerald" height={20} width={20} />
  //   ),
  // },
  // {
  //   label: "Chat Room",
  //   to: "/chat-room",
  //   icon: (
  //     <Icon icon="chatRoom" className="fill-emerald" height={20} width={20} />
  //   ),
  // },
  // {
  //   label: "Help Center",
  //   to: "/help-center",
  //   icon: (
  //     <Icon icon="helpCenter" className="fill-emerald" height={20} width={20} />
  //   ),
  // },
];
