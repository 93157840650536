import { Suspense, SVGProps, VFC } from "react";

import { icons } from "./icons";

export type IconProps = SVGProps<SVGElement> & {
  icon: keyof typeof icons;
  size?: number;
};

export const Icon: VFC<IconProps> = ({ icon, size = 20, ...rest }) => {
  const IconToRender = icons[icon];
  return (
    <Suspense fallback={<div style={{ width: size, height: size }} />}>
      <IconToRender height={size} width={size} {...rest} />
    </Suspense>
  );
};
