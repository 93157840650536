import { createContext, FC, useContext, useMemo, useState } from "react";

export type Localization = {
  country: "PE" | "US";
};

export const LocalizationContext = createContext<Localization>({
  country: "US",
});

export const LocalizationProvider: FC = ({ children }) => {
  const [country] = useState(
    process.env.REACT_APP_DEFAULT_COUNTRY as Localization["country"]
  );
  const value = useMemo(() => ({ country }), [country]);
  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
