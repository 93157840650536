import { toast } from "react-hot-toast";

import { getMyStore } from "../../api/get-my-store";
import { loginApi } from "../../api/login";
import { logoutApi } from "../../api/logout";
import { historyInstance } from "../../routing/history";

export const loginSubmitAction = (
  email: string,
  password: string,
  onSuccess: () => void
) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_SUBMIT" });
    loginApi(email, password)
      .then((response) => {
        const accessToken = response.data.data.access_token;
        localStorage.setItem("token", accessToken);
        return getMyStore();
      })
      .then((response) => {
        localStorage.setItem("storeId", String(response.id));
        dispatch(loginSuccessAction());
        toast.success("Success Access");
        dispatch(updateAuthenticated(true));
        historyInstance.push("/home");
        onSuccess();
      })
      .catch((err) => {
        dispatch(loginErrorAction());
        localStorage.removeItem("token");
        toast.error("Invalid credentials");
      });
  };
};

export const loginSuccessAction = () => {
  return {
    type: "LOGIN_SUCCESS",
  };
};

export const loginErrorAction = () => {
  return {
    type: "LOGIN_ERROR",
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    logoutApi().then();
    historyInstance.push("/login");
    localStorage.removeItem("token");
  };
};

export const updateAuthenticated = (newValue: boolean) => {
  return {
    type: "UPDATE_AUTHENTICATED",
    payload: newValue,
  };
};
