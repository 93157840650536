import { StoreSettingsInitialState } from "./initial-state";
import { StoreSettingsState } from "./type";

export const storeSettingsReducer = (
  state: StoreSettingsState = StoreSettingsInitialState,
  action
): StoreSettingsState => {
  switch (action.type) {
    case "STORE_SETTINGS_SUBMIT":
      return {
        ...state,
        loading: true,
      };
    case "STORE_SETTINGS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };

    case "STORE_SETTINGS_ERROR":
      return {
        ...state,
        loading: false,
        error: "Hubo un error",
      };
    case "UPDATE_QUALITIES":
      return {
        ...state,
        qualities: action.payload,
      };
    default:
      return state;
  }
};
