import { lazy } from "react";

import SubscriptionHandler from "../pages/SubscriptionHandler";

import { IRoute } from "./types";

const devRoutes: IRoute[] = [
  {
    path: "/components-test",
    component: lazy(() => import("../pages/components-tests")),
    navbarType: "common",
    showSidenav: false,
  },
];

export const routes: IRoute[] = [
  {
    path: "/",
    redirectTo: "/home",
    navbarType: "common",
    showSidenav: false,
  },
  {
    path: "/home",
    component: lazy(() => import("../pages/home-page/HomePage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Home",
  },
  {
    path: "/products",
    component: lazy(() => import("../pages/products/ProductsPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Products",
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/login/LoginPage")),
    navbarType: "common",
    showSidenav: false,
    title: "Login",
  },
  {
    path: "/forgot-password",
    component: lazy(
      () => import("../pages/forgot-password/ForgotPasswordPage")
    ),
    navbarType: "common",
    showSidenav: false,
    title: "Forgot password",
  },
  {
    path: "/store-settings",
    component: lazy(() => import("../pages/store-settings/StoreSettingsPage")),
    isProtected: true,
    navbarType: "common",
    showSidenav: false,
    title: "Store settings",
  },
  {
    path: "/add-product",
    component: lazy(() => import("../pages/add-product/AddProductPage")),
    isProtected: true,
    navbarType: "common",
    showSidenav: false,
    title: "Add products",
  },
  {
    path: "/new-product",
    component: lazy(() => import("../pages/new-product/NewProductPage")),
    isProtected: true,
    navbarType: "common",
    showSidenav: false,
    title: "New products",
  },
  {
    path: "/members-login",
    component: lazy(() => import("../pages/members-login/MembersLoginPage")),
    navbarType: "common",
    showSidenav: false,
    title: "Members login",
  },
  {
    path: "/payment-method",
    component: lazy(
      () => import("../pages/store-payment-method/PaymentMethodPage")
    ),
    isProtected: true,
    navbarType: "common",
    showSidenav: false,
    title: "Payment method",
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../pages/dashboard/DashboardPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Dashboard",
  },
  {
    path: "/sign-up",
    component: lazy(() => import("../pages/sign-up/SignUpPage")),
    navbarType: "common",
    showSidenav: false,
    title: "Sign up",
  },
  {
    path: "/edit-payment-method",
    navbarType: "dashboard",
    component: lazy(
      () => import("../pages/edit-store-payment-method/EditPaymentMethodPage")
    ),
    isProtected: true,
    showSidenav: false,
    title: "Edit payment method",
  },
  {
    path: "/edit-product/:id",
    component: lazy(() => import("../pages/edit-product/EditProductPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Edit product",
  },
  {
    path: "/create-product",
    component: lazy(() => import("../pages/create-product/CreateProductPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Create product",
  },
  {
    path: "/create-collection",
    component: lazy(
      () => import("../pages/manage-collection/ManageCollection")
    ),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Create collection",
  },
  {
    path: "/edit-collection/:id",
    component: lazy(
      () => import("../pages/manage-collection/ManageCollection")
    ),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Edit collection",
  },
  {
    path: "/invoices",
    component: lazy(() => import("../pages/invoices/InvoicesPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Invoices",
  },
  {
    path: "/invoices-details",
    component: lazy(
      () => import("../pages/invoices-details/InvoicesDetailsPage")
    ),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Invoices details",
  },
  {
    path: "/account-settings",
    component: lazy(
      () => import("../pages/account-settings/AccountSettingsPage")
    ),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: false,
    title: "Account settings",
  },
  {
    path: "/subscription",
    component: SubscriptionHandler,
    navbarType: "dashboard",
    showSidenav: false,
  },
  {
    path: "/recover-password",
    component: lazy(
      () => import("../pages/recover-password/RecoverPasswordPage")
    ),
    navbarType: "common",
    showSidenav: false,
    title: "Recover password",
  },
  {
    path: "/impact-score",
    component: lazy(() => import("../pages/impact-score/ImpactScorePage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Impact score",
  },
  {
    path: "/review-score",
    component: lazy(() => import("../pages/review-score/ReviewScorePage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Review score",
  },
  {
    path: "/products-reviews/:id",
    component: lazy(() => import("../pages/review-score/ReviewScorePage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Review score",
  },
  {
    path: "/orders",
    component: lazy(() => import("../pages/orders/OrdersPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Orders",
  },
  {
    path: "/orders/:id",
    component: lazy(() => import("../pages/order-details/OrderDetailsPage")),
    isProtected: true,
    navbarType: "dashboard",
    showSidenav: true,
    title: "Details",
  },
  ...(process.env.NODE_ENV === "development" ? devRoutes : []),
];
