import { VFC } from "react";

import { ReactComponent as LoadingBars } from "../../svgs/loading-bars.svg";

export const LoadingOverlay: VFC<{ show?: boolean }> = ({ show }) => {
  return show ? (
    <div className="fixed left-0 top-0 w-full h-full flex items-center justify-center z-[100]">
      <div className="absolute w-full h-full bg-black opacity-50" />
      <LoadingBars className="relative" width={80} height={80} />
    </div>
  ) : null;
};
