import { FC } from "react";

import { useLocation } from "react-router-dom";
import classnames from "classnames";

import { useSetSentryScope } from "../../hooks/use-set-sentry-scope";
import { Navbar } from "../navbar";
import { Sidenav } from "../Sidenav";

export type AppShellProps = {};

export const AppShell: FC<AppShellProps> = ({ children }) => {
  const route = useLocation().pathname;
  const shellClass = classnames("bg-shade min-h-screen px-10 pt-10", {
    "pb-16": route !== "/profile",
    "pt-12": route !== "/profile",
  });
  useSetSentryScope();
  return (
    <div className="flex">
      <Sidenav />
      <div className="flex-1">
        <Navbar />
        <div className={shellClass}>
          <div className=" ">{children}</div>
        </div>
      </div>
    </div>
  );
};
