import axios from "axios";

export const getMyProfile = (): Promise<{ email: string }> =>
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/profile`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then(({ data }) => data.data);
