// import { Axios } from "axios";

import axios from "axios";

// Axios.post(
//     url,{headers: {
//         "Authorization" : localStorage.getItem("token", accessToken);
//       }}
// )

export const logoutApi = () => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};
