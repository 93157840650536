import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { getMyStore } from "../api/get-my-store";
import { useSelector } from "../redux/store";

import { useLogout } from "./use-logout";

export const useAppInitialization = () => {
  const { logout } = useLogout({ skipNavigate: true });
  const authenticated = useSelector((state) => state.login.authenticated);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!authenticated) {
      setLoading(false);
      return;
    }
    getMyStore()
      .then((store) => {
        if (!store.connected) {
          if (
            !["/payment-method", "/subscription"].includes(location.pathname)
          ) {
            // navigate("/payment-method", { replace: true });
          }
        }
      })
      .catch((err: AxiosError) => {
        if (err?.response?.status === 401) {
          if (!["/recover-password"].includes(location.pathname)) {
            logout();
            navigate("/login", { replace: true });
          }
        } else {
          navigate("/store-settings", { replace: true });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    loading,
  };
};
