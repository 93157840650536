import { SVGProps, VFC } from "react";

import { ReactComponent as LoadingRC } from "../../svgs/oval.svg";

type Props = SVGProps<SVGElement> & {
  size?: number;
};

export const Loading: VFC<Props> = ({ size, ...rest }) => (
  <LoadingRC height={size} width={size} {...rest} />
);
