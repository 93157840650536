import { signUpInitialState } from "./initial-state";
import { SignUpState } from "./type";

export const signUpReducer = (
  state: SignUpState = signUpInitialState,
  action
): SignUpState => {
  switch (action.type) {
    case "SIGNUP_SUBMIT":
      return {
        ...state,
        loading: true,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
      };

    case "SIGNUP_ERROR":
      return {
        ...state,
        loading: false,
        error: "Hubo un error",
      };
    case "UPDATE_USER_ALREADY_EXISTS":
      return {
        ...state,
        userAlreadyExists: action.payload,
      };

    default:
      return state;
  }
};
