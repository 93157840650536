import { useEffect, VFC } from "react";

import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

const SubscriptionHandler: VFC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParams.get("success") === "true") {
      navigate("/home", { replace: true });
      return;
    }
    toast.error("Subscription failed");
    navigate("/payment-method?subscription=failed", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default SubscriptionHandler;
