import { VFC } from "react";

import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

import { useSelector } from "../redux/store";

import { IRoute, ProtectedRoute, RedirectRoute } from "./types";

const isProtectedRoute = (route: IRoute): route is ProtectedRoute =>
  (route as ProtectedRoute).isProtected;

const isRedirectRoute = (route: IRoute): route is RedirectRoute =>
  !!(route as RedirectRoute).redirectTo;

export const CustomRouteElement: VFC<{ route: IRoute }> = ({ route }) => {
  const titlePrefix = "Atelier Home Design - ";
  const authenticated = useSelector((state) => state.login.authenticated);
  if (isRedirectRoute(route)) {
    return <Navigate replace to={route.redirectTo} />;
  }
  if (isProtectedRoute(route) && !authenticated) {
    return <Navigate replace to="/login" />;
  }
  return (
    <>
      {route.title && (
        <Helmet>
          <title>{`${titlePrefix}${route.title}`}</title>
        </Helmet>
      )}
      <route.component />
    </>
  );
};
