import { FC, Suspense } from "react";

import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { AppShell } from "./components/Layout/AppShell";
import { LoadingOverlay } from "./components/LoadingOverlay";
import { useAppInitialization } from "./hooks/use-app-initialization";
import { I18nProvider } from "./i18n/i18nProvider";
import { LocalizationProvider } from "./localization/context";
import { store } from "./redux/store";
import { CustomRouteElement } from "./routing/CustomRouteElement";
import { CustomRouter } from "./routing/CustomRouter";
import { historyInstance } from "./routing/history";
import { routes } from "./routing/routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppInitializer: FC = ({ children }) => {
  const { loading } = useAppInitialization();
  return (
    <>
      <LoadingOverlay show={loading} />
      {!loading && children}
    </>
  );
};

function App() {
  return (
    <LocalizationProvider>
      <I18nProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <CustomRouter history={historyInstance}>
              <AppInitializer>
                <Toaster />
                <AppShell>
                  <Suspense fallback={<LoadingOverlay show />}>
                    <Routes>
                      {routes.map((route) => (
                        <Route
                          key={route.path}
                          path={route.path}
                          element={<CustomRouteElement route={route} />}
                        />
                      ))}
                    </Routes>
                  </Suspense>
                </AppShell>
              </AppInitializer>
            </CustomRouter>
          </Provider>
        </QueryClientProvider>
      </I18nProvider>
    </LocalizationProvider>
  );
}

export default App;
