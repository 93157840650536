import {
  TypedUseSelectorHook,
  useSelector as useSelectorRR,
} from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import { loginReducer } from "./login/reducer";
import { signUpReducer } from "./sign-up/reducer";
import { storeSettingsReducer } from "./store-settings/reducer";
import { AppState } from "./types";

export const store = createStore(
  combineReducers({
    login: loginReducer,
    signUp: signUpReducer,
    storeSettings: storeSettingsReducer,
  }),
  applyMiddleware(thunk, logger)
);

export const useSelector: TypedUseSelectorHook<AppState> = useSelectorRR;
