import { lazy } from "react";

const resolveToDefault = (promise) =>
  promise.then(({ ReactComponent }) => ({
    default: ReactComponent,
  }));

export const icons = {
  iconPlaceholder: lazy(() =>
    resolveToDefault(import("../../svgs/icon-placeholder.svg"))
  ),
  export: lazy(() => resolveToDefault(import("../../svgs/export.svg"))),
  pen: lazy(() => resolveToDefault(import("../../svgs/pen.svg"))),
  bookmark: lazy(() => resolveToDefault(import("../../svgs/bookmark.svg"))),
  threeDots: lazy(() => resolveToDefault(import("../../svgs/three-dots.svg"))),
  chevronDown: lazy(() =>
    resolveToDefault(import("../../svgs/chevron-down.svg"))
  ),
  chevronRight: lazy(() =>
    resolveToDefault(import("../../svgs/chevron-right.svg"))
  ),
  chevronLeft: lazy(() =>
    resolveToDefault(import("../../svgs/chevron-left.svg"))
  ),
  message: lazy(() => resolveToDefault(import("../../svgs/message.svg"))),
  notification: lazy(() =>
    resolveToDefault(import("../../svgs/notification.svg"))
  ),
  envelope: lazy(() => resolveToDefault(import("../../svgs/envelope.svg"))),
  addUser: lazy(() => resolveToDefault(import("../../svgs/add-user.svg"))),
  refresh: lazy(() => resolveToDefault(import("../../svgs/refresh.svg"))),
  timer: lazy(() => resolveToDefault(import("../../svgs/timer.svg"))),
  warning: lazy(() => resolveToDefault(import("../../svgs/warning.svg"))),
  error: lazy(() => resolveToDefault(import("../../svgs/error.svg"))),
  total: lazy(() => resolveToDefault(import("../../svgs/total.svg"))),
  carts: lazy(() => resolveToDefault(import("../../svgs/carts.svg"))),
  actions: lazy(() => resolveToDefault(import("../../svgs/actions.svg"))),
  add: lazy(() => resolveToDefault(import("../../svgs/add.svg"))),
  home: lazy(() => resolveToDefault(import("../../svgs/home.svg"))),
  bartCode: lazy(() => resolveToDefault(import("../../svgs/bart-code.svg"))),
  chatRoom: lazy(() => resolveToDefault(import("../../svgs/chat-room.svg"))),
  invoice: lazy(() => resolveToDefault(import("../../svgs/invoice.svg"))),
  chart: lazy(() => resolveToDefault(import("../../svgs/chart.svg"))),
  helpCenter: lazy(() =>
    resolveToDefault(import("../../svgs/help-center.svg"))
  ),
  circleCheck: lazy(() =>
    resolveToDefault(import("../../svgs/circle-check.svg"))
  ),
  save: lazy(() => resolveToDefault(import("../../svgs/save.svg"))),
  settings: lazy(() => resolveToDefault(import("../../svgs/settings.svg"))),
  trash: lazy(() => resolveToDefault(import("../../svgs/trash.svg"))),
  beta: lazy(() => resolveToDefault(import("../../svgs/beta.svg"))),
  iconBeta: lazy(() =>
    resolveToDefault(import("../../assets/images/atelierhorizontal.png"))
  ),
  star: lazy(() => resolveToDefault(import("../../svgs/star.svg"))),
  upload: lazy(() => resolveToDefault(import("../../svgs/upload.svg"))),
};
