import { VFC } from "react";

import { Link, matchPath, useLocation } from "react-router-dom";
import classNames from "classnames";

import ateliericonoBeta from "../../assets/images/atelierhorizontal.png";
import { routes } from "../../routing/routes";

import { menu } from "./menu";

export const Sidenav: VFC = () => {
  const { pathname } = useLocation();
  const showSidenav = routes.find(
    (_) => !!matchPath(_.path, pathname)
  )?.showSidenav;
  if (!showSidenav) {
    return null;
  }
  return (
    <div className="shadow bg-white w-[270px] z-50 sticky top-0 left-0 h-screen">
      <div className="h-navbar flex items-center justify-center">
        <Link to="/home">
          <div className="flex">
            <img src={ateliericonoBeta} alt="atelier" width={150} />
            <span className="mt-2.5  mb-1 ml-1 text-sm">BETA</span>
          </div>
        </Link>
      </div>
      <ul>
        {menu.map((menuItem, i) => (
          <li key={i}>
            <Link
              to={menuItem.to}
              className={classNames(
                "py-4 cursor-pointer block hover:bg-shade border-l-4",
                {
                  "border-l-greendashboard bg-shade": pathname === menuItem.to,
                  "border-l-transparent": pathname !== menuItem.to,
                }
              )}
            >
              <div className="flex items-center">
                <div className="w-12 flex justify-center">{menuItem.icon}</div>
                <span>{menuItem.label}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { menu } from "./menu";
