import React, { ButtonHTMLAttributes, FC } from "react";

import classnames from "classnames";

import { Loading } from "../Loading";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variation?: "filled" | "outline" | "text";
  rounded?: boolean;
  inversed?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
  fullwidth?: boolean;
  line?: boolean;
  color?: "primary" | "secondary" | "tertiary" | "gray" | "emerald" | "purple";
  size?: "md" | "lg";
  loading?: boolean;
};

export const Button: FC<ButtonProps> = ({
  variation = "filled",
  rounded = false,
  children,
  icon,
  iconPosition = "start",
  className,
  fullwidth,
  line,
  color = "primary",
  size = "md",
  type = "button",
  loading,
  disabled,
  ...rest
}) => {
  const buttonClass = classnames(
    className,
    "flex",
    "flex-row",
    "items-center",
    "justify-center",
    "px-8",
    "py-1.5",
    "rounded-full",
    "font-normal",
    "font-gotham",
    "min-h-[2.75rem]",
    {
      "opacity-70": disabled || loading,
      "text-white": variation === "filled",
      "border-white": variation === "text",
      "rounded-md": !rounded,
      "w-full": fullwidth,
      underline: line,
      border: variation !== "text",
      "text-buttonlg": size === "lg",
      "w-[320px]": size === "lg",
      "font-medium": size === "lg",
      "cursor-not-allowed": disabled,
    },
    color === "primary" && {
      "bg-gray-500": variation === "filled",
      "text-gray-600": variation === "text" || variation === "outline",
      "border-gray-600": true,
      "font-medium": true,
    },
    color === "secondary" && {
      "bg-magenta-500": variation === "filled",
      "text-magenta-600": variation === "text" || variation === "outline",
      "border-magenta-600": true,
      "font-medium": true,
    },
    color === "tertiary" && {
      "bg-lavender": variation === "filled",
      "text-gray-500": variation === "filled",
      "border-green-500": true,
    },
    color === "gray" && {
      "text-darkgray1": variation === "outline",
      "border-darkgray1": true,
    },
    color === "emerald" && {
      "border-emerald": true,
      "bg-emerald": variation === "filled",
    },
    color === "purple" && {
      "border-purple": true,
      "bg-purple": variation === "filled",
    }
  );

  const content = loading ? (
    <Loading height={20} width={20} stroke="#fff" strokeWidth={3} />
  ) : (
    <>
      {!!icon && iconPosition === "start" && (
        <span className="mr-2">{icon}</span>
      )}
      {children}
      {!!icon && iconPosition === "end" && <span className="ml-2">{icon}</span>}
    </>
  );

  return (
    <button
      disabled={loading || disabled}
      className={buttonClass}
      type={type}
      {...rest}
    >
      {content}
    </button>
  );
};
