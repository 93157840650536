import { VFC } from "react";

import { useLocation } from "react-router-dom";
import classNames from "classnames";

import { routes } from "../../routing/routes";

import { NavbarCommon } from "./navbar-common";
import { NavbarDashboard } from "./navbar-dashboard";

export const Navbar: VFC = () => {
  const { pathname } = useLocation();
  const useCommonNavbar =
    routes.find((_) => _.path === pathname)?.navbarType === "common";
  return (
    <div className="bg-white h-navbar sticky top-0 z-50 shadow">
      <div
        className={classNames("w-full mx-auto h-full", {
          "px-24": useCommonNavbar,
          "px-10": !useCommonNavbar,
        })}
      >
        {useCommonNavbar ? <NavbarCommon /> : <NavbarDashboard />}
      </div>
    </div>
  );
};
